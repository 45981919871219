




















import { Component, Vue } from "vue-property-decorator";
import { bindRobot, BindRobotReq } from "@/api/robot";
import { CommonRes } from "@/api/common";
import { Toast } from 'vant';
import {toqrscan} from "@/utils/appUtil";
interface MyWindow extends Window {
    setScanResult(qrcode: string): string;
}
declare var window: MyWindow;
@Component({
  components: {},
})
export default class Index extends Vue {
  private req: BindRobotReq = {
    robotId: "",
  };
  created() {
    console.log("bindRobotBox created");
     var that = this;
     window.setScanResult = that.setScanResult;
  }
  private async confirmBindRobot() {
    this.req.robotId = this.req.robotId.trim()
    if (this.req.robotId == "") {
      Toast(this.$t("robotNumberNull").toString());
      return;
    }
    let res: CommonRes = await bindRobot(this.req);
    if (res.code == 0) {
       localStorage.setItem("refresh", "true");
       this.$router.go(-1);
    }
  }
  private toqrscan(){
    toqrscan(this.$t("not_supported"));
  }
  public setScanResult(qrcode: string){
    this.req.robotId = qrcode
    return "success"
  }
}
